import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../../components';
import { USER_ROLE } from '../../../../constants/user';

import css from './TopbarDesktop.module.css';
import { createSlug } from '../../../../util/urlHelpers';
import { SUPPORTED_TABS } from '../../../../constants/listing';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    notificationCount,
    intl,
    isAuthenticated,
    currentUserRole,
    onLogout,

    currentUserHasListings,
    currentUserHasAnyListings,
    currentUserListingParams,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const lastTab = SUPPORTED_TABS[SUPPORTED_TABS.length - 1];

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink className={css.inboxLink} name="ManageBookingsPage">
      <span className={css.inbox}>
        <FormattedMessage id="General.yourBookings" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const messagesLink = authenticatedOnClientSide ? (
    <NamedLink className={css.inboxLink} name="EnquiriesPage">
      <span className={css.inbox}>
        <FormattedMessage id="General.yourEnquiries" />
      </span>
    </NamedLink>
  ) : null;

  const createProfileLink = !currentUserHasAnyListings && (
    <NamedLink className={css.artistProfileLink} name="NewListingPage">
      <span className={css.artistProfile}>
        <FormattedMessage id="General.createProfile" />
      </span>
    </NamedLink>
  );

  const { id, title, variant } = currentUserListingParams || {};

  const finishProfileLink = currentUserHasAnyListings && !currentUserHasListings && (
    <NamedLink
      className={css.artistProfileLink}
      name="EditListingPage"
      params={{ id, slug: createSlug(title), type: variant, tab: lastTab }}
    >
      <span className={css.artistProfile}>
        <FormattedMessage id="General.finishProfile" />
      </span>
    </NamedLink>
  );

  const viewProfileLink = currentUserHasListings && (
    <NamedLink
      className={css.artistProfileLink}
      name="ListingPage"
      params={{ id, slug: createSlug(title) }}
    >
      <span className={css.artistProfile}>
        <FormattedMessage id="General.viewProfile" />
      </span>
    </NamedLink>
  );

  const artistProfileLink =
    currentUserRole === USER_ROLE.ARTIST &&
    (createProfileLink || finishProfileLink || viewProfileLink);

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenuItems = [
    <MenuItem key="ManageBookingsPage">
      <NamedLink
        className={classNames(css.yourListingsLink, currentPageClass('ManageBookingsPage'))}
        name="ManageBookingsPage"
        to={currentUserRole === USER_ROLE.ARTIST ? { search: `?state=pending` } : null}
      >
        <span className={css.menuItemBorder} />
        <FormattedMessage id="General.yourBookings" />
      </NamedLink>
    </MenuItem>,

    <MenuItem key="ProfileSettingsPage">
      {currentUserRole === USER_ROLE.USER && (
        <NamedLink
          className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
        </NamedLink>
      )}
    </MenuItem>,
    <MenuItem key="AccountSettingsPage">
      <NamedLink
        className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
        name="AccountSettingsPage"
      >
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
      </NamedLink>
    </MenuItem>,
    <MenuItem key="logout">
      <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.logout" />
      </InlineTextButton>
    </MenuItem>,
  ];

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>{profileMenuItems}</MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signupCustomer" />
      </span>
    </NamedLink>
  );

  const signupArtistLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="ArtistSignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signupArtist" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />

      {artistProfileLink}
      {inboxLink}
      {messagesLink}
      {profileMenu}
      {signupLink}
      {signupArtistLink}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  currentUserRole: string,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
